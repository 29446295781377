import { getAge, getFormattedDateFromISOString } from 'helpers/date';
import { Condition, ConditionWithRelations } from 'interfaces/health-data';
import {
  AppointmentSummaryOutput,
  DiagnosisSummaryOutput,
  MedicalProblemOutput,
  MedicalProblemsContentType,
  MedicalRemainingProblemsOutput,
  MeetingContextContentType,
  MeetingContextOutput,
  MeetingDurationContentType,
  MeetingSummaryStatus,
  NoteDetail,
  ScribeOutputType,
  TextOutput,
} from 'node-api/scribe/scribe.types';
import { capitalize } from 'utils/helpers';
import { camelCaseToSentence } from 'utils/stringHelpers';
import { EMPTY_SYMBOL } from '../NoteEditionPreview/section';

export const ENABLED_TO_SHOW_IN_NOTES = false;
const LINE_SEPARATOR = '\n';

// TODO: Add this code in convertMedicalProblemsToString when we have the medical configuration ready to associate them with the medical problems section
/*
    ${`Exams:<br>${exams
      .map((data) => {
        const labAsString = `${data.procedure?.toUpperCase()}: ${data.status} (${
          data.date ? getFormattedDateFromISOString(data.date, 'YYYY-MM-DD') : 'N/A'
        })`;

        return labAsString;
      })
      .join('<br>')}`}
*/

export const asMultiLineString = (value?: string) => {
  if (!value) {
    return '';
  }

  return value
    .split(LINE_SEPARATOR)
    .map((l) => `• ${l}`)
    .join(LINE_SEPARATOR);
};

const convertMedicalProblemsToString = (problems: MedicalProblemOutput[]) => {
  const problemsAsStrings = problems
    .map((medicalProblem: MedicalProblemOutput) => {
      const { labs, medications } = medicalProblem.dataReview;

      return `
  <b>${medicalProblem?.name} ${medicalProblem?.icd10Code}</b><br><br>
  History:<br>${asMultiLineString(medicalProblem?.history) || 'N/A'}<br><br>
  Data Review:<br>
    ${`Labs:<br>${labs
      .map(
        (data) =>
          `<p>* ${data.observation?.toUpperCase()}: ${data.value} (${
            data.resultDate ? getFormattedDateFromISOString(data.resultDate, 'YYYY-MM-DD') : 'N/A'
          })</p>`
      )
      .join('')}`}
      <br>
      ${`Medications:<br>${medications
        .map(
          (data) =>
            `<p style="line-height: 0.4;">* ${capitalize(data.medication || '')} ${data.dosage}</p>`
        )
        .join('')}`}
      <br>
  Subjective Updates:<br>${asMultiLineString(medicalProblem?.subjectiveUpdates) || 'N/A'}<br><br>
  Assessment and Discussion:<br>${
    asMultiLineString(medicalProblem?.assessmentAndDiscussion) || 'N/A'
  }<br><br>
  Plan:<br>${asMultiLineString(medicalProblem?.plan) || 'N/A'}
`;
    })
    .join('<br><br>');

  return `<b>Problems:</b><br><br>${problemsAsStrings}<br><br>
  `;
};

const convertMedicalContextToString = (
  context: MeetingContextOutput,
  conditions: ConditionWithRelations[],
  note: NoteDetail
) => {
  const medicalProblems = note?.content?.find(
    (s) => s.outputType === ScribeOutputType.MedicalProblems
  ) as MedicalProblemsContentType;

  return `
  <p style="text-align: justify">
  ${context.patientName || EMPTY_SYMBOL} is a ${
    getAge(context.patientDOB) || EMPTY_SYMBOL
  } year old ${context.patientGender.toLowerCase() || EMPTY_SYMBOL} with BMI of ${
    note.meeting.rawAppointment.metadata.internalPatientBmi
  } with ${medicalProblems?.data
    .map((mp) => mp.name)
    .slice(0, -1)
    .join('; ')
    .toLowerCase()} and ${medicalProblems?.data
    ?.at(-1)
    ?.name.toLowerCase()} who presents for obesity screen, assessment, and treatment.
  </p>`;
};

const convertDiagnosisSummaryToString = (
  diagnosisSummary: DiagnosisSummaryOutput,
  note: NoteDetail,
  allConditions: ConditionWithRelations[]
) => {
  const conditionsByIcd10Code: { [icd10Code: string]: Condition } = {};

  for (let i = 0; i < allConditions.length; i++) {
    const { condition } = allConditions[i];
    conditionsByIcd10Code[condition.icd10Code] = condition;
  }

  const diagnosesFormatted = Object.entries(diagnosisSummary)
    .map(([premise, icd10Codes]) => {
      const diagnoses = icd10Codes
        .map((code: string) => `${conditionsByIcd10Code[code]?.name || 'unknown'} ${code}`)
        .join('; ');
      return `<li style="list-style: disc; display: list-item">${camelCaseToSentence(
        premise
      )}: <b>${diagnoses}</b></li>`;
    })
    .join('');

  const meetingDuration = note.content?.find(
    (c) => c.outputType === ScribeOutputType.MeetingDuration
  ) as MeetingDurationContentType;

  return `During this encounter, I spent approximately ${meetingDuration.data.meetingDuration} minutes addressing the following:
  <br>
  <ul style="list-style-type: disc">${diagnosesFormatted}</ul>`;
};

const convertAppointmentSummaryToString = (appointmentSummary: AppointmentSummaryOutput) => {
  return `
Originating site: ${appointmentSummary.originatingSite}<br>
Distant site: ${appointmentSummary.distantSite}<br>
Time started: ${getFormattedDateFromISOString(appointmentSummary.startingTime, 'hh:mm')}<br>
Time ended: ${getFormattedDateFromISOString(appointmentSummary.endingTime, 'hh:mm')}
      `;
};

const convertMedicalRemainingProblemsToString = (
  medicalRemainingProblems: MedicalRemainingProblemsOutput
) => {
  return `
 <p style="text-align: justify">
    The remaining problems were not addressed today. During this encounter,
    I reviewed the data points mentioned in the clinical note, including face to face interactions and time spent: <b>${medicalRemainingProblems.medicalProcedures?.join(
      ', '
    )}</b>. I also addressed the following problems: <b>${
    medicalRemainingProblems.additionalProblem || EMPTY_SYMBOL
  }</b>. Based on CMS definitions, I estimate that risk of treatment and diagnostic plan is
    <b>${medicalRemainingProblems?.mdmCode?.toLowerCase() || EMPTY_SYMBOL}</b>.
</p>
`;
};

const convertStaticTextToString = (
  data: TextOutput,
  conditions: ConditionWithRelations[],
  note: NoteDetail
) => {
  const meetingContextSection = note?.content?.find(
    (s) => s.outputType === ScribeOutputType.MeetingContext
  ) as MeetingContextContentType;

  return `<p style="text-align: justify">${`${
    meetingContextSection.data.patientName || EMPTY_SYMBOL
  } is a ${getAge(meetingContextSection.data.patientDOB) || EMPTY_SYMBOL} year old ${
    meetingContextSection.data.patientGender.toLowerCase() || EMPTY_SYMBOL
  } who is here for an Initial Evaluation and Assessment for Obesity. ${data.text}`}</p>`;
};

export const prepareSectionsForCopying = (
  note: NoteDetail,
  conditions: ConditionWithRelations[]
): ClipboardItem[] => {
  if (!note.content) {
    return [];
  }

  const content = note.content
    .sort((a, b) => a.order - b.order)
    .map((section) => {
      const { outputType, data } = section;

      switch (outputType) {
        case ScribeOutputType.MeetingContext:
          return convertMedicalContextToString(data, conditions, note);

        case ScribeOutputType.MedicalProblems:
          return convertMedicalProblemsToString(data);

        case ScribeOutputType.DiagnosisSummary:
          return convertDiagnosisSummaryToString(data, note, conditions);

        case ScribeOutputType.AppointmentSummary:
          return convertAppointmentSummaryToString(data);

        case ScribeOutputType.MedicalRemainingProblems:
          return convertMedicalRemainingProblemsToString(data);

        case ScribeOutputType.StaticText:
          return convertStaticTextToString(data, conditions, note);

        default:
          return '';
      }
    });

  const blob = new Blob(content, { type: 'text/html' });
  const clipboardItem = new ClipboardItem({ 'text/html': blob });

  return [clipboardItem];
};

export const convertSummaryStatusToReadableString = (
  meetingStatus: MeetingSummaryStatus | 'unknown'
): string => {
  switch (meetingStatus) {
    case MeetingSummaryStatus.ReadyForReview:
      return 'Ready for Review';
    case MeetingSummaryStatus.Signed:
      return 'Signed';
    case MeetingSummaryStatus.WaitingForSignature:
      return 'Waiting for Signature';
    default:
      return 'N/A';
  }
};
