import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import { debug } from 'utils/helpers';
import {
  GetAllSummariesByDateResponse,
  GetAllSummariesPayload,
  GetBriefSummariesByDateResponse,
  Meeting,
  NoteDetail,
  NoteSectionsWithStatus,
  SignSummaryResponse,
  UpdateSummaryResponse,
} from './scribe.types';

export default class ScribeClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.transcribe, false);
  }

  async getSummaries(params: GetAllSummariesPayload): Promise<GetAllSummariesByDateResponse> {
    const { data } = await this.axiosClient.get<GetAllSummariesByDateResponse>(
      `/meetings/summaries`,
      {
        params,
      }
    );
    return data;
  }

  async getSummary(summaryId: number): Promise<NoteDetail | null> {
    const { data } = await this.axiosClient.get<NoteDetail>(`/meetings/summaries/${summaryId}`);

    return data;
  }

  async updateSummary(
    summaryId: number,
    payload: NoteSectionsWithStatus
  ): Promise<UpdateSummaryResponse> {
    const response = await this.axiosClient.put<UpdateSummaryResponse>(
      `/meetings/summaries/${summaryId}`,
      payload
    );

    return response.data;
  }

  async signSummary(summaryId: number): Promise<SignSummaryResponse> {
    const response = await this.axiosClient.put<SignSummaryResponse>(
      `/meetings/summaries/${summaryId}/signatures`
    );

    return response.data;
  }

  async getBriefSummariesByFilters(
    appointmentIds: number[]
  ): Promise<GetBriefSummariesByDateResponse | null> {
    try {
      const response = await this.axiosClient.get<GetBriefSummariesByDateResponse>(
        '/meetings/summaries/brief',
        {
          params: {
            externalSchedulingIds: appointmentIds.join(','),
          },
        }
      );

      return response.data;
    } catch (error) {
      debug(error as string);
    }

    return null;
  }

  async regenerateMeetingSummaryWithUpdatedHealthData(summaryId: number): Promise<Meeting | null> {
    try {
      const response = await this.axiosClient.patch<Meeting>(
        `/meetings/summaries/${summaryId}/health-data`
      );

      return response.data;
    } catch (error) {
      debug(error as string);
    }

    return null;
  }
}

export const scribeClient = new ScribeClient();
