import { ConditionWithRelations } from 'interfaces/health-data';
import {
  AppointmentSummaryContentType,
  DiagnosisSummaryContentType,
  DiagnosisSummaryOutput,
  MedicalProblemOutput,
  MedicalProblemsContentType,
  MedicalRemainingProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  MeetingSummaryStage,
  NoteDetail,
  ScribeOutputType,
  StaticTextContentType,
} from 'node-api/scribe/scribe.types';
import '../../css/note-edition.css';
import { AppointmentSummarySection } from './AppointmentSummarySection';
import { DiagnosisSummarySection } from './DiagnosisSummarySection';
import { MedicalProblemSection, ProblemOption } from './MedicalProblemSection';
import { MeetingContextSection } from './MeetingContextSection';
import { MeetingRemainingProblems } from './MeetingRemainingProblems';
import { StaticTextSection } from './StaticTextSection';

export type SectionProps<T> = {
  content: T;
  stage: MeetingSummaryStage;
  handlers: NoteEditionHandlers;
  allConditions: ConditionWithRelations[];
  note: NoteDetail;
};

export type NoteEditionHandlers = {
  onChangeAppointmentSummary: (value: AppointmentSummaryContentType) => void;
  onChangeMeetingContext: (value: MeetingContextContentType) => void;
  onChangeMeetingDuration: (value: MeetingDurationContentType) => void;
  onDeleteDiagnosis: (
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>,
    value: string
  ) => void;
  onChangeMedicalProblem: (value: {
    sectionId: string;
    order: number;
    outputType: ScribeOutputType.MedicalProblems;
    data: MedicalProblemOutput;
    index: number;
  }) => void;
  onChangeMedicalRemainingProblems: (value: MedicalRemainingProblemsContentType) => void;
  onChangeDiagnosis: (value: {
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>;
    data: string[];
  }) => void;
  onAddProblem: (problem: ProblemOption) => void;
  onDeleteProblem: (problemPosition: number) => void;
  onChangeTextSection: (value: StaticTextContentType) => void;
};

export const renderNoteEditionSectionsByOutputType = (
  outputTypes: ScribeOutputType[],
  note: NoteDetail,
  stage: MeetingSummaryStage,
  handlers: NoteEditionHandlers,
  allConditions: ConditionWithRelations[]
): JSX.Element[] => {
  const data = note.content?.sort((a, b) => a.order - b.order) || [];

  const sections = data.filter((section) => outputTypes.includes(section.outputType));

  return sections.map((section) => {
    switch (section.outputType) {
      case ScribeOutputType.MeetingContext:
        return (
          <MeetingContextSection
            allConditions={allConditions}
            content={section as MeetingContextContentType}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );

      case ScribeOutputType.MedicalProblems:
        return (
          <MedicalProblemSection
            allConditions={allConditions}
            content={section as MedicalProblemsContentType}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );

      case ScribeOutputType.DiagnosisSummary: {
        const diagnosisContent = {
          ...section,
          meetingDurationContent: data.find(
            (s) => s.outputType === ScribeOutputType.MeetingDuration
          ),
        } as DiagnosisSummaryContentType & {
          meetingDurationContent: MeetingDurationContentType;
        };

        return (
          <DiagnosisSummarySection
            allConditions={allConditions}
            content={diagnosisContent}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );
      }

      case ScribeOutputType.AppointmentSummary:
        return (
          <AppointmentSummarySection
            allConditions={allConditions}
            content={section}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );

      case ScribeOutputType.MedicalRemainingProblems:
        return (
          <MeetingRemainingProblems
            allConditions={allConditions}
            content={section}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );

      case ScribeOutputType.StaticText:
        return (
          <StaticTextSection
            allConditions={allConditions}
            content={section}
            stage={stage}
            handlers={handlers}
            note={note}
          />
        );

      default:
        return <></>;
    }
  });
};
