import { Close, Info } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CopyIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import Loader from 'components/elements/loading/Loader';
import Toast from 'components/toast';
import { getFormattedDateFromISOString } from 'helpers/date';
import { scribeClient } from 'node-api/scribe/scribe-client';
import {
  GetAllSummariesByDateResponse,
  MeetingSummaryStage,
  NoteDetail,
  NoteDetailWithTranscription,
  NoteExcerpt,
} from 'node-api/scribe/scribe.types';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import AuthService from 'services/auth-service';
import { HealthDataContext } from 'state/contexts/healthData';
import { copyEnrichTextToClipboard, copyToClipboard } from 'utils/clipboard';
import '../../css/notes.css';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';
import '../css/note-edition.css';
import '../css/note-preview.css';
import '../css/note.css';
import { expandNoteTranscription, getTranscriptionAsText } from '../lib/transcriptions';
import { NoteEdition } from '../NoteEdition/NoteEdition';
import { renderSectionsByOutputType } from '../NoteEditionPreview/section';
import Transcription from '../Transcription/Transcription';
import { prepareSectionsForCopying } from './format';

type Props = {
  noteExcerpt: NoteExcerpt;
  fetchSummaries: () => Promise<GetAllSummariesByDateResponse>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

type Tabs = 'note' | 'transcript';

export const NotePreview = ({ noteExcerpt, fetchSummaries, setOpenModal }: Props) => {
  const { conditions } = useContext(HealthDataContext);

  const [note, setNote] = useState<NoteDetailWithTranscription | null>(null);
  const [canEditNote, setCanEditNote] = useState<boolean>(false);
  const [openCopiedToClipboardMessage, setOpenCopiedToClipboardMessage] = useState<boolean>(false);
  const [openSignModal, setOpenSignModal] = useState<boolean>(false);
  const [viewTranscripts, setViewTranscripts] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<Tabs>('note');
  const [playerStatus, setPlayerStatus] = useState<{ currentTime: number; isPlaying: boolean }>({
    currentTime: 0,
    isPlaying: false,
  });
  const [currentLineTime, setCurrentLineTime] = useState<number | null>(null);

  const handleSignNote = async () => {
    const response = await scribeClient.signSummary(noteExcerpt.meetingSummary.id);

    if (!response.status || !response.note?.meetingSummary) {
      Toast.show('error', 'There was an error updating note');
      return;
    }

    setNote({
      ...expandNoteTranscription(note as NoteDetail),
      meetingSummary: response.note.meetingSummary,
    });

    Toast.show('success', 'Note was signed successfully');

    await fetchSummaries();

    setOpenModal(false);
  };

  const handleCopyNote = () => {
    if (note) {
      if (tabValue === 'note') {
        copyEnrichTextToClipboard(prepareSectionsForCopying(note, conditions));
      } else {
        copyToClipboard(getTranscriptionAsText(note));
      }
    }

    setOpenCopiedToClipboardMessage(true);
  };

  const handleEditNote = () => setCanEditNote(!canEditNote);

  const handleSignModal = () => setOpenSignModal(!openSignModal);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: Tabs) => {
    setCurrentLineTime(null);
    setTabValue(newValue);
  };

  const handleUpdatePlayerStatus = (status: { currentTime: number; isPlaying: boolean }) => {
    setPlayerStatus(status);
  };

  const fetchSummaryWithTranscription = async () => {
    const [response, config] = await Promise.all([
      scribeClient.getSummary(noteExcerpt.meetingSummary.id),
      AuthService.getConfig(),
    ]);

    if (!response) {
      Toast.show('error', 'There was an error obtaining the note');
      return;
    }

    if (config && config.provider.scribeConfig.viewTranscripts !== undefined) {
      setViewTranscripts(config.provider.scribeConfig.viewTranscripts);
    } else {
      setViewTranscripts(true);
    }

    setNote(expandNoteTranscription(response));
  };

  useEffect(() => {
    (async () => fetchSummaryWithTranscription())();
  }, []);

  return note && canEditNote ? (
    <NoteEdition
      initialNote={note}
      fetchSummaries={fetchSummaries}
      onCancel={() => {
        fetchSummaryWithTranscription();
        setCanEditNote(false);
      }}
      setOpenModal={(v) => {
        setCanEditNote(!canEditNote);
        setOpenModal(v);
      }}
    />
  ) : (
    <Box className='note-preview-modal-container'>
      {!note ? (
        <Loader
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            margin: '25% 0',
          }}
        />
      ) : (
        <>
          <Box className='note-preview-container'>
            <Box className='note-preview-header'>
              <Box className='appointment-details'>
                <Box display='flex' alignItems='center'>
                  <Box className='note-list-row-icon' mr={1} />
                  <Typography
                    mr={1}
                    sx={{
                      color: '#404040',
                      opacity: '60%',
                    }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
                    note.meeting?.rawAppointment?.metadata?.startAt,
                    'h:mma'
                  )}`}</Typography>

                  <Typography
                    color='#323648'
                    mr={
                      1
                    }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>
                </Box>

                <IconButton size='small' onClick={() => setOpenModal(false)}>
                  <Close />
                </IconButton>
              </Box>

              <Box className='note-preview-content-header' mr={1}>
                <Typography
                  component='h4'
                  fontWeight={600}
                  color='#353535'
                  sx={{
                    fontSize: '18px !important',
                  }}>
                  Review Notes
                </Typography>

                <Box display='flex' className='note-preview-header-buttons'>
                  <Snackbar
                    message='Copied to clipboard'
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={2000}
                    onClose={() => setOpenCopiedToClipboardMessage(false)}
                    open={openCopiedToClipboardMessage}
                  />

                  {note.meetingSummary.stage === MeetingSummaryStage.PostVisit && (
                    <Button
                      className='note-preview-action-buttons'
                      variant='text'
                      startIcon={
                        note.meetingSummary.stage === MeetingSummaryStage.PostVisit && <CheckIcon />
                      }
                      onClick={handleSignModal}
                      disabled={false}
                      sx={{
                        marginRight: '1em',
                      }}>
                      Sign
                    </Button>
                  )}

                  {note.meetingSummary.stage === MeetingSummaryStage.PreVisit && (
                    <Button
                      className='note-preview-action-buttons'
                      variant='text'
                      startIcon={
                        <Tooltip title='The signature is only enabled after the medical initial'>
                          <Info
                            sx={{
                              color: '#404040',
                              opacity: '60%',
                              '&.MuiSvgIcon-root': {
                                fontSize: '1.2rem',
                              },
                            }}
                          />
                        </Tooltip>
                      }
                      sx={{
                        color: '#404040',
                        marginRight: '1rem',
                      }}>
                      <Box sx={{ color: '#404040', opacity: '60%' }}>Sign</Box>
                    </Button>
                  )}

                  <Button
                    className='note-preview-action-buttons'
                    variant='text'
                    startIcon={<EditIcon />}
                    onClick={handleEditNote}
                    sx={{
                      marginRight: '1rem',
                    }}>
                    Edit
                  </Button>

                  <Button
                    className='note-preview-action-buttons'
                    variant='text'
                    startIcon={<CopyIcon />}
                    onClick={handleCopyNote}
                    sx={{
                      marginRight: 0,
                    }}>
                    Copy
                  </Button>
                </Box>
              </Box>
            </Box>

            {note && viewTranscripts && note.meetingRecordingUrl && (
              <Box className='note-tab-selector' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label='Note' value='note' />
                  <Tab label='Transcript' value='transcript' />
                </Tabs>
              </Box>
            )}

            {tabValue === 'transcript' && (
              <>
                <Box className='note-preview-media-content-header' mr={1}>
                  <AudioPlayer
                    currentLineTime={currentLineTime}
                    audioSource={note.meetingRecordingUrl || ''}
                    onUpdatePlayerStatus={handleUpdatePlayerStatus}
                  />
                </Box>

                <Box className='note-preview-content-container transcript'>
                  {note.meetingTranscriptionDialog && (
                    <Transcription
                      isPlaying={playerStatus.isPlaying}
                      currentTime={playerStatus.currentTime}
                      transcription={note.meetingTranscriptionDialog}
                      onClickTextLine={(time) => setCurrentLineTime(time)}
                    />
                  )}
                </Box>
              </>
            )}

            {tabValue === 'note' && (
              <Box className='note-preview-content-container' id='note-content-preview'>
                {note.content
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((section) => {
                    return renderSectionsByOutputType(section, conditions, note);
                  })}

                {note.meetingSummary?.signatureHistory?.map(({ signedBy, signedOn }) => {
                  return (
                    <Typography key={`summary-signature-${signedBy}-${signedOn}`}>{`Signed by ${
                      signedBy.firstName
                    } ${signedBy.lastName}, ${
                      signedBy.specialty
                    } at ${getFormattedDateFromISOString(
                      signedOn,
                      'MM/DD/YYYY h:mm A z'
                    )}`}</Typography>
                  );
                })}
              </Box>
            )}
          </Box>

          <Modal open={openSignModal} className='preview-sign-note-modal'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              className='note-sign-modal'>
              <Typography
                textAlign='center'
                mb={2}
                fontWeight={600}
                sx={{
                  fontSize: '18px',
                }}>
                Sign Medical Appointment Note
              </Typography>
              <Typography
                textAlign='center'
                mb={3}
                sx={{
                  fontSize: '16px',
                }}>
                Please review and sign the note from the recent medical appointment
              </Typography>
              <Box display='flex' gap={2}>
                <Button
                  fullWidth
                  className='note-sign-button'
                  variant='outlined'
                  onClick={handleSignModal}>
                  Dismiss
                </Button>
                <Button
                  fullWidth
                  className='note-sign-button confirm'
                  variant='contained'
                  onClick={handleSignNote}>
                  Sign Now
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};
