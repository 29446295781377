import { Box, Typography } from '@mui/material';
import { getAge } from 'helpers/date';
import {
  MedicalProblemsContentType,
  MeetingContextContentType,
  ScribeOutputType,
} from 'node-api/scribe/scribe.types';
import { EMPTY_SYMBOL } from '../../NoteEditionPreview/section';
import { SectionProps } from './render-sections';

export const MeetingContextSection = ({
  content,
  note,
}: SectionProps<MeetingContextContentType>) => {
  const medicalProblems = note?.content?.find(
    (s) => s.outputType === ScribeOutputType.MedicalProblems
  ) as MedicalProblemsContentType;

  return (
    <Box className='edit-section' mb={3} key={`note-edition-${content.sectionId}`}>
      <Typography fontWeight='600' mb={1}>
        Context
      </Typography>

      <Typography textAlign='justify'>
        {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
        {content.data.patientGender.toLowerCase()} with BMI of{' '}
        {note?.meeting.rawAppointment.metadata.internalPatientBmi || EMPTY_SYMBOL} with{' '}
        {medicalProblems?.data
          ? `${medicalProblems?.data
              .map((mp) => mp.name)
              .slice(0, -1)
              .join('; ')} and ${medicalProblems?.data?.at(-1)?.name}`.toLowerCase()
          : EMPTY_SYMBOL}{' '}
        who presents for obesity screen, assessment, and treatment.
      </Typography>
    </Box>
  );
};
