import { CopyAll, Sync } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Spinner from 'components/elements/loaders/spinner';
import { getFormattedDateFromISOString } from 'helpers/date';
import { ConditionWithRelations } from 'interfaces/health-data';
import { NoteDetail } from 'node-api/scribe/scribe.types';
import { useState } from 'react';
import './node-edition-preview.css';
import { renderSectionsByOutputType } from './section';

export type Props = {
  conditions: ConditionWithRelations[];
  note: NoteDetail;
  onCancel: () => void;
  onCopyNoteContent: () => void;
  onResyncHealthData: () => Promise<void>;
};

export const NoteEditionPreview = ({
  conditions,
  note,
  onCancel,
  onCopyNoteContent,
  onResyncHealthData,
}: Props) => {
  const [resyncingHealthData, setResyncingHealthData] = useState<boolean>(false);

  const handlePreResyncData = async (): Promise<void> => {
    setResyncingHealthData(true);

    await onResyncHealthData();

    setResyncingHealthData(false);
  };

  return (
    <Box className='note-edition-preview-container'>
      <Box className='note-edition-content-header'>
        <Typography
          component='h4'
          fontWeight={600}
          color='#353535'
          sx={{ fontSize: '18px !important' }}>
          Preview
        </Typography>

        <Box display='flex'>
          <Button size='small' onClick={onCancel} className='action-button'>
            Back to preview
          </Button>
          {resyncingHealthData ? (
            <Box className='action-button' display='flex' alignItems='center' gap={1}>
              <Spinner size={15} />
              <Typography>Syncing...</Typography>
            </Box>
          ) : (
            <Button
              size='small'
              onClick={handlePreResyncData}
              className='action-button'
              startIcon={<Sync />}>
              Re-sync data
            </Button>
          )}
          <Button
            size='small'
            startIcon={<CopyAll />}
            onClick={onCopyNoteContent}
            className='action-button'>
            Copy
          </Button>
        </Box>
      </Box>

      <Box className='note-edition-content' id='note-edition-content-preview'>
        {note.content
          ?.sort((a, b) => a.order - b.order)
          .map((section) => {
            return renderSectionsByOutputType(section, conditions, note);
          })}

        {note.meetingSummary?.signatureHistory?.map(({ signedBy, signedOn }, index) => {
          return (
            <Typography key={`note-edition-preview-${signedBy}-${signedOn}-${index}`}>{`Signed by ${
              signedBy.firstName
            } ${signedBy.lastName}, ${signedBy.specialty} at ${getFormattedDateFromISOString(
              signedOn,
              'MM/DD/YYYY h:mm A z'
            )}`}</Typography>
          );
        })}
      </Box>
    </Box>
  );
};
