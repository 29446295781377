import { Checkbox, FormControlLabel } from '@mui/material';
import { Specialty } from 'node-api/schedule/Appointments.types';
import { useEffect, useState } from 'react';

export type NoteCheckboxFilter = { name: string; value: string; checked: boolean };

export const checkboxFilters: NoteCheckboxFilter[] = [
  {
    name: 'View All',
    value: 'all',
    checked: false,
  },
  {
    name: 'Medical Provider',
    value: Specialty.Medical,
    checked: false,
  },
  {
    name: 'Nutritional Specialist',
    value: Specialty.Nutritional,
    checked: false,
  },
  {
    name: 'Diagnostics',
    value: Specialty.Metabolism,
    checked: false,
  },
  {
    name: 'Exercise Specialist',
    value: Specialty.Exercise,
    checked: false,
  },
];

type Props = {
  onCheck: (filters: { name: string; value: string; checked: boolean }[]) => void;
  showAllNotes: boolean;
};

export const NoteCheckboxFilters = ({ onCheck, showAllNotes }: Props) => {
  const [filters, setFilters] = useState<NoteCheckboxFilter[]>(checkboxFilters);

  useEffect(() => {
    const filtersChecked = filters.map((f) => ({ ...f, checked: showAllNotes || f.checked }));
    setFilters(filtersChecked);
  }, [showAllNotes]);

  useEffect(() => {
    onCheck(filters);
  }, [filters]);

  return (
    <>
      {filters.map(({ name, checked, value }, i) => (
        <FormControlLabel
          key={`note-checkbox-filter-form-${i}`}
          control={
            <Checkbox
              name={name}
              value={value}
              key={`note-checkbox-filter-${i}-${name}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === 'all') {
                  setFilters((prev) =>
                    prev.map((p) => ({
                      ...p,
                      checked: e.target.checked,
                    }))
                  );
                } else {
                  setFilters((prev) =>
                    prev.map((filter) => {
                      const isViewAllFilter = filter.value === 'all';

                      const shouldUncheckViewAll = isViewAllFilter && !e.target.checked;
                      const shouldCheckFilter =
                        e.target.name === filter.name ? e.target.checked : filter.checked;

                      return {
                        ...filter,
                        checked: shouldUncheckViewAll ? false : shouldCheckFilter,
                      };
                    })
                  );
                }
              }}
              size='small'
            />
          }
          label={name.replace(/([A-Z])/g, ' $1').replace(/^./, (v) => v.toUpperCase())}
          checked={checked}
          sx={{
            margin: 0,
            span: {
              fontSize: '1em',
              fontWeight: 500,
            },
          }}
        />
      ))}
    </>
  );
};
