import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import '../../css/table.css';

type Props = {
  columns: GridColDef[];
  data: GridValidRowModel[];
  onChange?: (updatedRows: GridValidRowModel[]) => void;
  actions?: {
    delete?: boolean;
  };
};

export const CustomTable = ({ columns, data, actions, onChange }: Props) => {
  const [rows, setRows] = useState<GridValidRowModel[]>(data.map((d) => ({ ...d, id: v4() })));
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowClick = (params: GridRowParams) => {
    setRowModesModel((prev) => ({
      ...prev,
      [params.id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params) => {
    setRowModesModel((prev) => ({
      ...prev,
      [params.id]: { mode: GridRowModes.View },
    }));
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? { ...newRow } : row));
    setRows(updatedRows);

    if (onChange) {
      onChange(updatedRows);
    }
    return newRow;
  };

  const deleteRow = useCallback(
    (deletedId: GridRowId) => () => {
      setTimeout(() => {
        setRows((prevRows) => {
          const updatedRows = prevRows.map((row) =>
            row.id === deletedId ? { ...row, deleted: true } : row
          );

          if (onChange) {
            onChange(updatedRows);
          }

          return updatedRows;
        });
      });
    },
    []
  );

  const columnsMemorized = useMemo<GridColDef[]>(
    () => [
      ...columns,
      ...(actions && Object.keys(actions || {}).length > 0
        ? [
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              minWidth: 40,
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              getActions: (params: GridRowParams) => [
                ...(actions.delete
                  ? [
                      <GridActionsCellItem
                        icon={<DeleteOutlineIcon />}
                        label='Delete'
                        onClick={deleteRow(params.id)}
                      />,
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ],
    [deleteRow]
  );

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}>
      <DataGrid
        className='custom-panel-table'
        rows={rows.filter((row) => !row.deleted)}
        columns={columnsMemorized}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowClick={handleRowClick}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnFilter
        disableColumnMenu
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        localeText={{ noRowsLabel: 'There is no information to show' }}
      />
    </Box>
  );
};
