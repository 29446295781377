import { PickersDayProps } from '@mui/lab';
import { Badge, Box, FormGroup, Grid, Typography } from '@mui/material';
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loader from 'components/elements/loading/Loader';
import dayjs, { Dayjs } from 'dayjs';
import { SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY } from 'globalConstants';
import { getDateFromMillis, getFormattedDateFromISOString } from 'helpers/date';
import { Patient } from 'lib/types';
import { Specialty } from 'node-api/schedule/Appointments.types';
import { scribeClient } from 'node-api/scribe/scribe-client';
import { useEffect, useState } from 'react';
import { getItemFromLocalStorage } from 'utils/helpers';
import {
  GetAllSummariesByDateResponse,
  NoteExcerptWithProvider,
} from '../../../../node-api/scribe/scribe.types';
import '../css/notes.css';
import './css/list-note.css';
import { NoteCheckboxFilter, NoteCheckboxFilters } from './NoteCheckboxFilters';
import { NotesList } from './NotesList';

export const SpecialtyColorsMapping = {
  [Specialty.Medical]: '#7BDBFF',
  [Specialty.Exercise]: '#FF9083',
  [Specialty.Metabolism]: '#adadad',
  [Specialty.Nutritional]: '#30FFC1',
  other: '#404040',
};

function getDefaultMonthForCalendar(): string {
  // When notes are opened from the calendar view or active sessions,
  // let's fetch this so we have the note available in advance
  const cachedNoteDate = getItemFromLocalStorage(
    SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY,
    true
  )?.startAt;

  return cachedNoteDate
    ? getFormattedDateFromISOString(cachedNoteDate, 'yyyy-MM')
    : getFormattedDateFromISOString(getDateFromMillis(Date.now()), 'yyyy-MM');
}

type NotesListFilters = {
  month: string;
  specialties: string[];
};

export const Notes = ({ patient }: { patient: Patient }) => {
  const [searchBy, setSearchBy] = useState<NotesListFilters>({
    month: getDefaultMonthForCalendar(),
    specialties: [],
  });
  const [notes, setNotes] = useState<{
    [date: string]: NoteExcerptWithProvider[];
  } | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(
    getItemFromLocalStorage(SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY, true)?.startAt ??
      new Date().toISOString()
  );
  const [showAllNotes, setShowAllNotes] = useState<boolean>(true);

  const handleCheck = (filters: NoteCheckboxFilter[]) => {
    setNotes(null);
    setSearchBy((prev) => ({
      ...prev,
      specialties: filters.filter((f) => f.checked && f.value !== 'all').map((f) => f.value),
    }));
  };

  const handleSelectedDate = (v: Date) => {
    setShowAllNotes(false);
    setSelectedDate(new Date(v).toISOString());
  };

  const handleSelectedMonth = (month: Dayjs) => {
    setShowAllNotes(true);
    setSelectedDate(month.toISOString());
    setSearchBy({
      ...searchBy,
      month: getFormattedDateFromISOString(month.toISOString(), 'yyyy-MM'),
    });
  };

  const fetchSummariesAndSaveNotes = async (): Promise<GetAllSummariesByDateResponse> => {
    const response = await scribeClient.getSummaries({
      internalPatientId: `${patient.uuid}`,
      month: searchBy.month,
      ...(searchBy.specialties.length > 0 && { specialties: searchBy.specialties.join(',') }),
    });

    setNotes(response.data);

    return response;
  };

  const retrieveColorBySpecialty = (internalCode: string) => {
    switch (true) {
      case internalCode.includes(Specialty.Medical):
        return SpecialtyColorsMapping[Specialty.Medical];

      case internalCode.includes(Specialty.Metabolism):
        return SpecialtyColorsMapping[Specialty.Metabolism];

      case internalCode.includes(Specialty.Nutritional):
        return SpecialtyColorsMapping[Specialty.Nutritional];

      case internalCode.includes(Specialty.Exercise):
        return SpecialtyColorsMapping[Specialty.Exercise];

      default:
        return SpecialtyColorsMapping.other;
    }
  };

  const serverDay = (props: PickersDayProps<unknown>) => {
    const { day, outsideCurrentMonth, isLastVisibleCell, isFirstVisibleCell } = props;

    const date = day?.$d?.toISOString()?.split('T')?.[0];

    const specialtyColorsPerDay =
      notes?.[date]?.reduce((pv, cv) => {
        const color = retrieveColorBySpecialty(cv.meetingType.internalCode);

        return pv.includes(color) ? pv : [...pv, color];
      }, [] as string[]) ?? [];

    return (
      <Badge
        key={day.toString()}
        overlap='circular'
        badgeContent={specialtyColorsPerDay.map((color, i) => {
          return (
            <div
              className='note-specialty-badge'
              key={`${day.toString()}-${i}`}
              style={{ backgroundColor: color }}
            />
          );
        })}>
        <PickersDay
          isLastVisibleCell={isLastVisibleCell}
          isFirstVisibleCell={isFirstVisibleCell}
          onDaySelect={() => null}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disabled={!!!notes?.[date]}
        />
      </Badge>
    );
  };

  useEffect(() => {
    (async () => {
      await fetchSummariesAndSaveNotes();
    })();
  }, [searchBy]);

  return (
    <Grid container className='notes-list-container' flexDirection='row'>
      <Grid container item md={3} flexDirection='column' wrap='nowrap'>
        <Grid item padding={2}>
          <Box width='100%' height={250} mb={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                onChange={handleSelectedDate}
                onMonthChange={handleSelectedMonth}
                views={['year', 'month', 'day']}
                slots={{
                  day: serverDay,
                }}
                value={dayjs(selectedDate)}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item padding={2} className='note-filters'>
          <Typography fontSize='1em' mb={1}>
            Filter by appointment type
          </Typography>
          <FormGroup>
            <NoteCheckboxFilters onCheck={handleCheck} showAllNotes={showAllNotes} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid md={9} className='note-list-sections'>
        {notes ? (
          <NotesList
            notes={notes}
            currentDate={selectedDate}
            showAllNotes={showAllNotes}
            fetchSummaries={fetchSummariesAndSaveNotes}
          />
        ) : (
          <Loader
            style={{
              alignContent: 'center',
              justifyContent: 'center',
              margin: '25% 0',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
