import {
  CPTCode,
  MDMCode,
  MedicalBehavioralProblem,
  MedicalProcedure,
  MeetingDurationRange,
} from './medical';

export enum MeetingSummaryStatus {
  ReadyForReview = 'READY_FOR_REVIEW',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE',
  Signed = 'SIGNED',
}

export enum MeetingSummaryStage {
  PreVisit = 'PRE_VISIT',
  PostVisit = 'POST_VISIT',
}

export enum ScribeOutputType {
  MeetingDuration = 'meeting-duration',
  MeetingContext = 'meeting-context',
  MedicalProblems = 'medical-problems',
  MedicalRemainingProblems = 'medical-remaining-problems',
  DiagnosisSummary = 'diagnosis-summary',
  AppointmentSummary = 'appointment-summary',
  StaticText = 'static-text',
}

export enum MeetingContextLocation {
  Telehealth = 'Telehealth',
  InPerson = 'In-person',
  Unknown = 'Unknown',
}

export enum MeetingContextPatientGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Unknown = 'Unknown',
}

export interface MeetingContextOutput {
  patientDOB: string; // Date as string
  patientName: string;
  patientGender: MeetingContextPatientGender;
  appointmentLocation: MeetingContextLocation;
  mainProblemCode?: string;
  mainProblem?: string; // DEPRECATE THIS
}

export interface MeetingDurationOutput {
  cptCode: CPTCode;
  meetingDuration: string; // in minutes, as string
  meetingDurationRange: MeetingDurationRange;
}

export interface MedicalProblemOutput {
  name: string;
  history?: string;
  dataReview: DataReviewSection;
  icd10Code: string;
  subjectiveUpdates?: string;
  plan?: string;
  assessmentAndDiscussion?: string; // TODO: use this section
}

export interface MedicalRemainingProblemsOutput {
  mdmCode?: MDMCode;
  additionalProblem?: MedicalBehavioralProblem;
  medicalProcedures?: MedicalProcedure[]; // note: up to 5
}

export interface DiagnosisSummaryOutput {
  stableChronicDecease?: string[];
  minorOrSelfLimitedProblems?: string[];
  acuteUncomplicatedIllnessOrInjury?: string[];
  acuteComplicatedInjury?: string[];
  acuteIllnessWithSystemicSymptoms?: string[];
  acuteOrChronicIllnessOrInjury?: string[];
  chronicDiseaseOrTreatmentSideEffect?: string[];
  severeChronicDiseaseOrTreatmentSideEffect?: string[];
  newUndiagnosedProblem?: string[];
}

export interface AppointmentSummaryOutput {
  originatingSite: string; // default text as Patient’s home
  distantSite: string; // default text as clinic name
  startingTime: string; //  as date in the following format 12:00 pm
  endingTime: string; // as time in the following format 1:00 pm
}

export interface TextOutput {
  text: string;
}

export type MeetingSummaryResultsOutput =
  | MeetingContextOutput
  | MeetingDurationOutput
  | MedicalProblemOutput
  | MedicalRemainingProblemsOutput
  | DiagnosisSummaryOutput
  | AppointmentSummaryOutput
  | TextOutput;

export interface MeetingSummarySection {
  id: number;
  meetingSummaryId: number;
  meetingTemplateSectionId: number | null;
  resolvedPrompt: string;
  results: MeetingSummaryResultsOutput;
  outputType: ScribeOutputType;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export type MeetingSummary = {
  id: number;
  meetingId: number;
  meetingTemplateId: number;
  signatureHistory?: {
    signedBy: {
      firstName: string;
      lastName: string;
      specialty: string;
    };
    signedOn: string;
  }[];
  stage: MeetingSummaryStage;
  status: MeetingSummaryStatus;
  createdAt: string;
  updatedAt: string;
};

export type ProviderNoteData = {
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
};

export type NoteExcerpt = {
  meetingSummary: MeetingSummary;
  meeting: Meeting;
  meetingType: MeetingType;
};

export enum MeetingTypeStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum MeetingStatus {
  PENDING = 'PENDING',
  TRANSCRIBING = 'TRANSCRIBING',
  COLLECTING_DATA = 'COLLECTING_DATA',
  SUMMARIZING = 'SUMMARIZING',
  SUMMARY_COMPLETED = 'SUMMARY_COMPLETED',
}

export enum PatientGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Unknown = 'Unknown',
}

export interface ScheduledMeetingMetadata {
  appointmentType: string;
  clinicName: string;
  concatenatedPipelineId?: string;
  endAt: string;
  environment?: string;
  internalPatientBmi: number;
  internalPatientDOB: string;
  internalPatientFullName: string;
  internalPatientGender: PatientGender;
  internalPatientId: string;
  internalPatientUuid: string;
  internalProviderFirstName: string;
  internalProviderFullName: string;
  internalProviderId: string;
  internalProviderLastName: string;
  internalProviderUuid: string;
  pipelineId?: string;
  startAt: string;
}

export type Meeting = {
  id: number;
  meetingTypeId: number;
  internalProviderId: string;
  internalPatientId: string;
  internalAppointmentId: string;
  rawAppointment: {
    createdAt: string;
    externalSchedulingId: number;
    metadata: {
      appointmentType: string;
      clinicName: string;
      endAt: string;
      internalPatientBmi: number | null;
      internalPatientDOB: string;
      internalPatientFullName: string;
      internalPatientGender: string;
      internalPatientId: string;
      internalPatientUuid: string;
      internalProviderFirstName: string;
      internalProviderFullName: string;
      internalProviderId: string;
      internalProviderLastName: string;
      internalProviderUuid: string;
      startAt: string;
      syncAttempts: number;
    };
    updatedAt: string;
  };
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  status: MeetingStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type MeetingType = {
  id: number;
  customerId: number;
  medicalSpecialtyId: number | null;
  meetingTemplateId: number;
  internalCode: string;
  status: MeetingTypeStatus;
  createdAt: string;
  updatedAt: string;
};
export interface MeetingContextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingContext;
  data: MeetingContextOutput;
}

export interface MeetingDurationContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingDuration;
  data: MeetingDurationOutput;
}

export interface MedicalProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalProblems;
  data: MedicalProblemOutput[];
}

export interface MedicalRemainingProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalRemainingProblems;
  data: MedicalRemainingProblemsOutput;
}

export interface DiagnosisSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.DiagnosisSummary;
  data: DiagnosisSummaryOutput;
}

export interface AppointmentSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.AppointmentSummary;
  data: AppointmentSummaryOutput;
}

export interface StaticTextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.StaticText;
  data: TextOutput;
}

export type SummaryContentType =
  | DiagnosisSummaryContentType
  | MedicalProblemsContentType
  | MedicalRemainingProblemsContentType
  | MeetingDurationContentType
  | MeetingContextContentType
  | AppointmentSummaryContentType
  | StaticTextContentType;

export type NoteSectionsWithStatus = {
  content?: SummaryContentType[];
};

export type UpdateSummaryResponse = {
  status: boolean;
  note?: NoteDetail;
};

export type SignSummaryResponse = {
  status: boolean;
  note?: Pick<NoteDetail, 'meetingSummary'> & { status: MeetingSummaryStatus };
};
export interface LaboratoryResult {
  id: string;
  observation?: string;
  value?: string;
  interpretation?: string;
  referenceRange?: string;
  codeType?: string;
  code?: string;
}

export interface AuthorResponse {
  id: string;
  name: string;
  authoredOn?: string;
}

export enum MedicationStatus {
  Active = 'active',
  Stopped = 'stopped',
}

export interface MedicationResponse {
  id: string;
  patientId: string;
  medication: string;
  instructions?: string;
  dosage?: string;
  status?: MedicationStatus;
  code?: string;
  date?: string;
}

export interface EncounterResponse {
  id: string;
  patientId: string;
  encounter?: string;
  location?: string;
  category?: string;
  startDate?: string;
  endDate?: string;
  notes: EncounterNoteResponse[];
}

export interface EncounterNoteResponse {
  id: string;
  encounterId: string;
  author?: string;
  facility?: string;
  noteDate?: string;
  rawDetails?: string;
}

export interface ConditionResponse {
  id: string;
  patientId: string;
  condition: string;
  code?: string;
  firstSeen?: string;
  lastSeen?: string;
  status?: string;
}

export interface ProcedureResponse {
  id: string;
  patientId: string;
  procedure?: string;
  code?: string;
  date?: string;
  status?: string;
}

export interface SocialHistoryResponse {
  id: string;
  patientId: string;
  observation: string;
  value?: string;
  code?: string;
  date?: string;
}

export interface VitalSignResponse {
  id: string;
  patientId: string;
  observation: string;
  value?: string;
  code?: string;
  date?: string;
}

export interface LaboratoryResultResponse {
  id: string;
  patientId: string;
  observation?: string;
  value?: string;
  interpretation?: string;
  referenceRange?: string;
  code?: string;
  resultDate?: string;
}

export interface AllergyResponse {
  id: string;
  patientId: string;
  description: string;
  manifestation: string;
  code?: string;
  firstSeen?: string;
  lastSeen?: string;
  status?: string;
}

export interface GetBriefSummariesByDateResponse {
  [date: string]: BriefSummary[];
}

export type BriefSummary = {
  summaryId: number;
  status: MeetingSummaryStatus;
  meetingId: number;
  externalSchedulingId: number | null;
  stage: MeetingSummaryStage;
};

export type EntityWithSummaryInfo<T> = T &
  Partial<{
    meetingStatus: MeetingSummaryStatus;
    meetingId: number;
  }>;

export type GetAllSummariesPayload = {
  internalProviderId?: string;
  externalProviderId?: string;
  internalPatientId: string;
  specialties?: string;
  page?: number;
  month: string;
  pageSize?: number;
  status?: MeetingSummaryStatus;
};

// NOTE: [time, label, content]
export type MeetingTranscriptionDialogLine = [string, string, string];

export interface MeetingTranscriptionDialog {
  providerConfig: { token: string; value: string };
  patientConfig: { token: string; value: string };
  lines: MeetingTranscriptionDialogLine[];
}

export interface MeetingTranscriptionDialogWithTimeRanges extends MeetingTranscriptionDialog {
  timeRanges: [number, number][]; // [from, until] in seconds
}

export type NoteDetail = {
  meeting: Meeting;
  meetingType: MeetingType;
  meetingSummary: MeetingSummary;
  date: string;
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  meetingRecordingUrl: string | null;
  meetingTranscriptionDialog: MeetingTranscriptionDialog | null;
} & NoteSectionsWithStatus;

export type NoteDetailWithTranscription = NoteDetail & {
  meetingTranscriptionDialog: MeetingTranscriptionDialogWithTimeRanges | null;
};

export type NoteExcerptWithProvider = NoteExcerpt & ProviderNoteData;

export type GetAllSummariesByDateResponse = {
  data: { [date: string]: NoteExcerptWithProvider[] };
};

export const EvaluationAndManagementByCPTCode: {
  [cptCode: number]: {
    meetingDurationRange: MeetingDurationRange;
  };
} = {
  99212: {
    meetingDurationRange: MeetingDurationRange.TenToNineteen,
  },
  99213: {
    meetingDurationRange: MeetingDurationRange.TwentyToTwentyNine,
  },
  99214: {
    meetingDurationRange: MeetingDurationRange.ThirtyToThirtyNine,
  },
  99215: {
    meetingDurationRange: MeetingDurationRange.FortyToSixtyFixe,
  },
};

export type Laboratory = Omit<LaboratoryResultResponse, 'id' | 'patientId'>;
export type Medication = Omit<MedicationResponse, 'id' | 'patientId'> & { isTaking: boolean };
export type Procedure = Omit<ProcedureResponse, 'id' | 'patientId'>;

export type DataReviewSection = {
  vitals: VitalSignResponse[];
  labs: Laboratory[];
  medications: Medication[];
  exams: Procedure[];
};
