import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY } from 'globalConstants';
import { getFormattedDateFromISOString } from 'helpers/date';
import {
  GetAllSummariesByDateResponse,
  NoteExcerptWithProvider,
} from 'node-api/scribe/scribe.types';
import { useEffect, useState } from 'react';
import { HealthDataProvider } from 'state/contexts/healthData';
import { getItemFromLocalStorage } from 'utils/helpers';
import { NotePreview } from '../note/NotePreview/NotePreview';
import { NoteListItem } from './NoteListItem';

export type NotesListProps = {
  notes: { [date: string]: NoteExcerptWithProvider[] };
  currentDate: string | null;
  showAllNotes: boolean;
  fetchSummaries: () => Promise<GetAllSummariesByDateResponse>;
};

export const NotesList = ({ notes, currentDate, showAllNotes, fetchSummaries }: NotesListProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedNoteExcerpt, setSelectedNoteExcerpt] = useState<NoteExcerptWithProvider | null>(
    null
  );
  const [notesByMonth, setNotesByMonth] = useState<[string, NoteExcerptWithProvider[]][]>([]);

  useEffect(() => {
    const allNotes = Object.entries(notes);

    const cachedMeetingId = getItemFromLocalStorage(
      SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY,
      true
    )?.meetingId;

    if (cachedMeetingId) {
      const found = allNotes
        .reduce((prev, curr) => [...prev, ...curr[1]], [] as NoteExcerptWithProvider[])
        .find((n) => n.meetingSummary.meetingId === cachedMeetingId);

      if (found) {
        setSelectedNoteExcerpt(found);
        setOpenModal(true);
      }
    }

    localStorage.removeItem(SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY);

    setNotesByMonth(allNotes);
  }, [notes]);

  if (notesByMonth.length === 0) {
    return (
      <Typography
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100%'>
        There are no notes for the selected date or month
      </Typography>
    );
  }

  const handleViewNote = (noteExcerpt: NoteExcerptWithProvider): void => {
    setSelectedNoteExcerpt(noteExcerpt);
    setOpenModal(true);
  };

  return (
    <HealthDataProvider>
      {selectedNoteExcerpt && (
        <Modal open={openModal} onClose={() => setSelectedNoteExcerpt(null)} className='note-modal'>
          <NotePreview
            noteExcerpt={selectedNoteExcerpt}
            fetchSummaries={fetchSummaries}
            setOpenModal={setOpenModal}
          />
        </Modal>
      )}

      <Box>
        {notesByMonth.map(([date, notesByDate]) => {
          const selectedDate = currentDate?.substring(0, 10);
          const month = currentDate?.substring(0, 7);

          return (
            (date === selectedDate || (showAllNotes && month && date?.includes(month))) && (
              <Box className='note-section' key={date}>
                <Box
                  className='note-section-title note-section-element-row'
                  display='flex'
                  justifyContent='space-between'>
                  <Typography fontWeight={500}>
                    {getFormattedDateFromISOString(date, 'MMMM DD, YYYY')}
                  </Typography>
                  <Typography fontWeight={500}>
                    {' '}
                    {getFormattedDateFromISOString(date, 'dddd')}
                  </Typography>
                </Box>

                <Box className='note-section-list'>
                  {notesByDate.map((noteExcerpt, i) => {
                    return (
                      <NoteListItem
                        key={`note-section-list-${noteExcerpt.meetingSummary.id}-${i}`}
                        noteExcerpt={noteExcerpt}
                        onViewNoteClick={handleViewNote}
                      />
                    );
                  })}
                </Box>
              </Box>
            )
          );
        })}
      </Box>
    </HealthDataProvider>
  );
};
