import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import { getFormattedDateFromISOString } from 'helpers/date';
import {
  MeetingSummaryStage,
  MeetingSummaryStatus,
  NoteExcerptWithProvider,
} from '../../../../node-api/scribe/scribe.types';
import './css/list-note.css';

export type NoteListItemProps = {
  noteExcerpt: NoteExcerptWithProvider;
  onViewNoteClick: (noteExcerpt: NoteExcerptWithProvider) => void;
};

export const NoteListItem = ({ noteExcerpt, onViewNoteClick }: NoteListItemProps) => {
  const statusesForSignaturePending = [
    MeetingSummaryStatus.WaitingForSignature,
    MeetingSummaryStatus.ReadyForReview,
  ];

  const {
    meetingSummary: { signatureHistory, status },
  } = noteExcerpt;

  const itWasPreviouslySigned = signatureHistory && signatureHistory?.length > 0;

  return (
    <Box
      className='note-section-list-row note-section-element-row'
      display='flex'
      gap={2}
      justifyContent='space-between'
      alignItems='center'>
      <Box display='flex' alignItems='center' gap={1} minWidth='50%'>
        <Box className='note-list-row-icon' />
        <Typography
          sx={{
            color: '#404040',
            opacity: '60%',
          }}>
          {noteExcerpt.meetingType.internalCode}
        </Typography>
        <Typography
          sx={{
            color: '#404040',
            opacity: '60%',
          }}>{` ${getFormattedDateFromISOString(
          noteExcerpt.meeting?.rawAppointment?.metadata.startAt,
          'h:mma'
        )}`}</Typography>
        <Typography>{`${noteExcerpt.provider?.firstName || 'unknown'} ${
          noteExcerpt.provider?.lastName || 'unknown'
        }, `}</Typography>
        <Typography>{noteExcerpt.provider?.specialty}</Typography>
      </Box>

      <Box display='flex' minWidth='20%' width='auto' sx={{ textWrap: 'nowrap' }}>
        <Box display='flex' alignItems='center'>
          {statusesForSignaturePending.includes(status) && !itWasPreviouslySigned ? (
            <>
              <CloseIcon
                sx={{
                  marginRight: '5px',
                  fontSize: '13px',
                  color: '#85868C',
                }}
              />

              <Typography fontSize='1em' margin='auto' color='#85868C'>
                Signature pending
              </Typography>
            </>
          ) : (
            <>
              <CheckIcon
                sx={{
                  marginRight: '5px',
                  fontSize: '13px',
                  color: '#323648',
                }}
              />

              <Typography fontSize='1em' margin='auto' color='#323648'>
                Signed
              </Typography>
            </>
          )}
        </Box>

        <Button
          onClick={() => onViewNoteClick(noteExcerpt)}
          sx={{
            minWidth: '8em',
            fontWeight: 500,
            textTransform: 'none',
            fontSize: '15px',
            marginLeft: '10px',
          }}
          fullWidth>
          View{' '}
          {noteExcerpt.meetingSummary.stage === MeetingSummaryStage.PreVisit
            ? 'Pre Note'
            : 'Post Note'}
        </Button>
      </Box>
    </Box>
  );
};
