import { Autocomplete, Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { MDMCode, MedicalBehavioralProblem, MedicalProcedure } from 'node-api/scribe/medical';
import {
  MedicalRemainingProblemsContentType,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe.types';
import { useState } from 'react';
import { SectionProps } from './render-sections';

const MAX_PROCEDURES_PER_NOTE = 5;

type Option<T> = { label: string; value: T };

export const MeetingRemainingProblems = ({
  content,
  stage,
  handlers,
}: SectionProps<MedicalRemainingProblemsContentType>) => {
  const [medicalProcedures, setMedicalProcedures] = useState<Option<MedicalProcedure>[]>(
    content.data.medicalProcedures?.map((p) => {
      return { label: p, value: p };
    }) || []
  );

  const handleSelect = (procedures: Option<MedicalProcedure>[]) => {
    setMedicalProcedures(procedures);

    handlers.onChangeMedicalRemainingProblems({
      ...content,
      data: {
        ...content.data,
        medicalProcedures: procedures.map((op) => op.value),
      },
    });
  };

  const medicalProceduresOptions = Object.values(MedicalProcedure).map((procedure) => ({
    label: procedure,
    value: procedure,
  }));

  return (
    <Box className='edit-section' mb={3} key={`note-edition-${content.sectionId}`}>
      <Typography mb={1} fontWeight='600'>
        Remaining problems
      </Typography>

      <Typography textAlign='justify'>
        The remaining problems were not addressed today. During this encounter, I reviewed the data
        points mentioned in the clinical note, including face to face interactions and time spent:
        <Autocomplete
          disabled={stage === MeetingSummaryStage.PreVisit}
          multiple
          size='small'
          limitTags={MAX_PROCEDURES_PER_NOTE}
          options={medicalProceduresOptions}
          getOptionLabel={(option) => option.label}
          disableListWrap
          getOptionDisabled={(option) =>
            medicalProcedures.length === MAX_PROCEDURES_PER_NOTE ||
            !!medicalProcedures.find((p) => p.value === option.value)
          }
          value={medicalProcedures}
          fullWidth
          onChange={(_: unknown, newValue: Option<MedicalProcedure>[] | null) =>
            handleSelect(newValue as Option<MedicalProcedure>[])
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={`Select max ${MAX_PROCEDURES_PER_NOTE} procedures...`}
            />
          )}
          sx={{ margin: '1em 0 1em 0' }}
        />
        I additionally addressed the following problems:{' '}
        <Select
          disabled={stage === MeetingSummaryStage.PreVisit}
          id='meeting-mdm-code-select'
          size='small'
          sx={{ fontSize: '14px', borderRadius: '40px', height: '1.5rem' }}
          value={content.data.additionalProblem || ''}
          onChange={(v) => {
            handlers.onChangeMedicalRemainingProblems({
              ...content,
              data: {
                ...content.data,
                additionalProblem: v.target.value as MedicalBehavioralProblem,
              },
            });
          }}>
          {Object.values(MedicalBehavioralProblem).map((problemName) => (
            <MenuItem key={`context-${problemName}`} value={problemName}>
              {problemName}
            </MenuItem>
          ))}
        </Select>{' '}
        Based on CMS definitions, I estimate that risk of treatment and diagnostic plan is{' '}
        <Select
          disabled={stage === MeetingSummaryStage.PreVisit}
          id='meeting-mdm-code-select'
          size='small'
          sx={{ fontSize: '14px', borderRadius: '40px', height: '1.5rem' }}
          value={content.data.mdmCode || ''}
          onChange={(v) => {
            handlers.onChangeMedicalRemainingProblems({
              ...content,
              data: {
                ...content.data,
                mdmCode: v.target.value as MDMCode,
              },
            });
          }}>
          {Object.values(MDMCode).map((mdmCode) => (
            <MenuItem key={`context-${mdmCode}`} value={mdmCode}>
              {mdmCode}
            </MenuItem>
          ))}
        </Select>
      </Typography>
    </Box>
  );
};
