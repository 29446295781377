import { Box, List, ListItem, Typography } from '@mui/material';
import { getAge, getFormattedDateFromISOString } from 'helpers/date';
import { Condition, ConditionWithRelations } from 'interfaces/health-data';
import {
  AppointmentSummaryContentType,
  DiagnosisSummaryContentType,
  MedicalProblemsContentType,
  MedicalRemainingProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  NoteDetail,
  ScribeOutputType,
  StaticTextContentType,
  SummaryContentType,
} from 'node-api/scribe/scribe.types';
import { capitalize } from 'utils/helpers';
import { camelCaseToSentence } from 'utils/stringHelpers';
import { asMultiLineString, ENABLED_TO_SHOW_IN_NOTES } from '../NotePreview/format';

export const EMPTY_SYMBOL = '___';

const renderDiagnosisSummarySection = (
  content: DiagnosisSummaryContentType,
  note: NoteDetail,
  allConditions: ConditionWithRelations[]
) => {
  const dataEntries = Object.entries(content.data);
  const withData = dataEntries.some(([, diagnoses]) => diagnoses.length > 0);

  const conditionsByIcd10Code: { [icd10Code: string]: Condition } = {};

  for (let i = 0; i < allConditions.length; i++) {
    const { condition } = allConditions[i];
    conditionsByIcd10Code[condition.icd10Code] = condition;
  }

  const meetingDuration = note.content?.find(
    (c) => c.outputType === ScribeOutputType.MeetingDuration
  ) as MeetingDurationContentType;

  return (
    <Box mb={2}>
      <Typography>
        During this encounter, I spent approximately {meetingDuration.data.meetingDuration} minutes
        addressing the following: {!withData && <strong>{EMPTY_SYMBOL}</strong>}
      </Typography>

      {withData &&
        dataEntries.map(([premise, icd10Codes]) => {
          if (!Array.isArray(icd10Codes) || icd10Codes.length === 0) {
            return [];
          }

          const diagnosisPremise = premise.replace(/([A-Z])/g, ' $1');

          const diagnoses = icd10Codes
            .map((code: string) => `${conditionsByIcd10Code[code]?.name || 'unknown'} ${code}`)
            .join('; ');

          return (
            <List sx={{ listStyleType: 'disc', paddingLeft: '1em' }}>
              <ListItem sx={{ display: 'list-item', listStyle: 'disc', paddingLeft: '5px' }}>
                {`${diagnosisPremise[0].toUpperCase() + diagnosisPremise.slice(1)}: `}
                <b>{diagnoses}</b>
              </ListItem>
            </List>
          );
        })}
    </Box>
  );
};

const renderMedicalProblemsSection = (content: MedicalProblemsContentType) => {
  return (
    <>
      <Typography mb={2}>
        <strong>Problems:</strong>
      </Typography>

      {content.data.map((problem) => {
        const { labs, medications, ...otherDataReviewSections } = problem.dataReview;
        return (
          <Box mb={2}>
            <Typography mb={2} fontWeight={600}>
              {`${problem.name} - ${problem.icd10Code}`}
            </Typography>

            <Typography pl={4}>History:</Typography>
            <Typography mb={2} pl={6}>
              {asMultiLineString(problem?.history)}
            </Typography>
            <Typography pl={4}>Data Review:</Typography>
            <Typography mb={2} pl={6}>
              <Typography textTransform='capitalize' mb={1}>
                Labs
              </Typography>

              {labs.map((data) => {
                return (
                  <Box>
                    <Typography>{`${data.observation}: ${data.value} (${
                      data.resultDate
                        ? getFormattedDateFromISOString(data.resultDate, 'YYYY-MM-DD')
                        : 'N/A'
                    })`}</Typography>
                  </Box>
                );
              })}

              <Typography textTransform='capitalize' mb={1}>
                Medications
              </Typography>

              {medications.map((data) => {
                return (
                  <Box mb='5px'>
                    <Typography>{`* ${capitalize(data.medication)} ${data.dosage}`}</Typography>
                  </Box>
                );
              })}

              {/*
              // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section
              <Typography textTransform='capitalize' fontWeight={600} mb={1}>
                Exams
              </Typography>

              {exams.map((data) => {
                return (
                  <Box>
                    <Box pl={1}>
                      <Typography>{`${data.procedure}: ${data.status} (${
                        data.date ? getFormattedDateFromISOString(data.date, 'YYYY-MM-DD') : 'N/A'
                      })`}</Typography>
                      <br />
                    </Box>
                  </Box>
                );
              })}
              */}

              {ENABLED_TO_SHOW_IN_NOTES &&
                Object.entries(otherDataReviewSections ?? {}).map(([section, data]) => {
                  return (
                    <Box>
                      <Typography textTransform='capitalize' fontWeight={600} mb={1}>
                        {section}
                      </Typography>
                      {data.map((d) => {
                        return (
                          <Box pl={1}>
                            {Object.entries(d).map(([key, value]) => {
                              return (
                                <Typography>{`${camelCaseToSentence(key)}: ${value}`}</Typography>
                              );
                            })}
                            <br />
                          </Box>
                        );
                      })}
                    </Box>
                  );
                })}
            </Typography>
            <Typography pl={4}>Subjective Updates:</Typography>
            <Typography mb={2} pl={6}>
              {asMultiLineString(problem?.subjectiveUpdates)}
            </Typography>
            <Typography pl={4}>Assessments and Discussion:</Typography>
            <Typography mb={2} pl={6}>
              {asMultiLineString(problem?.assessmentAndDiscussion)}
            </Typography>
            <Typography pl={4}>Plan:</Typography>
            <Typography mb={2} pl={6}>
              {asMultiLineString(problem?.plan)}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

const renderMedicalRemainingProblemsSection = (content: MedicalRemainingProblemsContentType) => {
  return (
    <Box mb={2}>
      <Typography mb={2} textAlign='justify'>
        The remaining problems were not addressed today. During this encounter, I reviewed the data
        points mentioned in the clinical note, including face to face interactions and time spent{' '}
        <strong>{content?.data?.medicalProcedures?.join(', ') || EMPTY_SYMBOL}</strong>. I also
        addressed the following problems:{' '}
        <strong>{content.data.additionalProblem || EMPTY_SYMBOL}</strong>. Based on CMS definitions,
        I estimate that risk of treatment and diagnostic plan is{' '}
        <strong>{content.data.mdmCode?.toLowerCase() || EMPTY_SYMBOL}</strong>.
      </Typography>
    </Box>
  );
};

const renderMeetingContextSection = (content: MeetingContextContentType, note: NoteDetail) => {
  const medicalProblems = note?.content?.find(
    (s) => s.outputType === ScribeOutputType.MedicalProblems
  ) as MedicalProblemsContentType;

  return (
    <Typography mb={2} textAlign='justify'>
      {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
      {content.data.patientGender.toLowerCase()} with BMI of{' '}
      {note?.meeting.rawAppointment.metadata.internalPatientBmi || EMPTY_SYMBOL} with{' '}
      {medicalProblems?.data
        ? `${medicalProblems?.data
            .map((mp) => mp.name)
            .slice(0, -1)
            .join('; ')} and ${medicalProblems?.data?.at(-1)?.name}`.toLowerCase()
        : EMPTY_SYMBOL}{' '}
      who presents for obesity screen, assessment, and treatment.
    </Typography>
  );
};

const renderAppointmentSummarySection = (content: AppointmentSummaryContentType) => {
  return (
    <Box mb={2}>
      <Typography>Originating site: {content.data.originatingSite}</Typography>
      <Typography>Distant site: {content.data.distantSite}</Typography>
      <Typography>
        Time started: {getFormattedDateFromISOString(content.data.startingTime, 'hh:mm')}
      </Typography>
      <Typography>
        Time ended: {getFormattedDateFromISOString(content.data.endingTime, 'hh:mm')}
      </Typography>
    </Box>
  );
};

const renderStaticTextSection = (content: StaticTextContentType, note: NoteDetail) => {
  const meetingContextSection = note?.content?.find(
    (s) => s.outputType === ScribeOutputType.MeetingContext
  ) as MeetingContextContentType;

  return (
    <Typography mb={2} textAlign='justify'>
      {`${meetingContextSection.data.patientName || EMPTY_SYMBOL} is a ${
        getAge(meetingContextSection.data.patientDOB) || EMPTY_SYMBOL
      } year old ${
        meetingContextSection.data.patientGender.toLowerCase() || EMPTY_SYMBOL
      } who is here for an Initial Evaluation and Assessment for Obesity.
      ${content.data.text}`}
    </Typography>
  );
};

export const renderSectionsByOutputType = (
  data: SummaryContentType,
  conditions: ConditionWithRelations[],
  note: NoteDetail
) => {
  const { outputType } = data;

  switch (outputType) {
    case ScribeOutputType.MeetingContext:
      return renderMeetingContextSection(data as MeetingContextContentType, note);

    case ScribeOutputType.MedicalProblems:
      return renderMedicalProblemsSection(data as MedicalProblemsContentType);

    case ScribeOutputType.MedicalRemainingProblems:
      return renderMedicalRemainingProblemsSection(data as MedicalRemainingProblemsContentType);

    case ScribeOutputType.DiagnosisSummary:
      return renderDiagnosisSummarySection(data as DiagnosisSummaryContentType, note, conditions);

    case ScribeOutputType.AppointmentSummary:
      return renderAppointmentSummarySection(data as AppointmentSummaryContentType);

    case ScribeOutputType.StaticText:
      return renderStaticTextSection(data as StaticTextContentType, note);

    default:
      return <></>;
  }
};
