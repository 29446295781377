import axios from 'axios';
import { Badge } from 'components/elements';
import { Notes } from 'components/elements/notes/list/Notes';
import Agreements from 'components/main/agreements/Agreements';
import AssessmentTab from 'components/main/assessment/AssessmentTab';
import CalendarTab from 'components/main/calendar/CalendarTab';
import ScheduleTab from 'components/main/calendar/ScheduleTab';
import CardsPendingCommentsTab from 'components/main/cards/CardsPendingComments/CardsPendingCommentsTab';
import Pictures from 'components/main/pictures';
import AppointmentListForTabs from 'components/main/profile/list-appointment';
import { ProfileTabComponent } from 'components/main/profile/profile-tab-component';
import { CARD_REVIEW_LIST_NAMES, PICTURES_REVIEW_LIST_NAMES } from 'config';
import { EFeatures } from 'lib/feature/features.types';
import { MemberMainTabs, Patient, User } from 'lib/types';
import { RpmProviderEvent } from 'node-api/rpm/ProviderTimeTrackerClient';
import { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import AuthService from 'services/auth-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import PictureService from 'services/picture';
import { rpmEventTrackingServiceInstance } from 'services/rpm/rpm-track-event-service';
import { FeaturesContext } from 'state/contexts/features';
import { isFromSendGridMissedAppointments } from 'utils/helpers';
import EventList from '../../rpm-event/list';
import { checkRole } from '../../rpm-event/util';
import { Tab, Tabs } from './style';

type TabsBoxProps = {
  patient: Patient;
  user: User;
  reviewList?: string;
};

interface TabItemProps {
  tabName: string;
  tabLabel?: string;
  isSelected: boolean;
  isVisible?: boolean;
  onClick: () => void;
  mixpanelProps?: { [key: string]: string | string[] };
}

const TabItem = ({
  tabName,
  tabLabel,
  isSelected,
  isVisible = true,
  onClick,
  mixpanelProps,
}: TabItemProps) => {
  return (
    <>
      {isVisible ? (
        <Tab
          selected={isSelected}
          onClick={() => {
            MixpanelWrapperInstance.track(EventNames.ProfileClickTab, {
              'Tab Name': tabName,
              ...mixpanelProps,
            });
            onClick();
          }}>
          {tabLabel || tabName}
        </Tab>
      ) : null}
    </>
  );
};

const ProfileTabsBox: FC<TabsBoxProps> = ({ patient, user, reviewList }) => {
  const [profileTabUnreviewed, setProfileTabUnreviewed] = useState<boolean>(false);
  const [picturesTabUnreviewed, setPicturesTabUnreviewed] = useState<boolean>(false);
  const [displayMore, setDisplayMore] = useState<boolean>(false);
  const [displayNotes, setDisplayNotes] = useState<boolean>(false);

  const { featuresState } = useContext(FeaturesContext);
  const { search, pathname } = useLocation();

  const history = useHistory();
  const match = useRouteMatch<{ id: string; selectedTab: string }>();
  const pathTab = match.params?.selectedTab;

  const selectedTab: MemberMainTabs | null =
    Object.values(MemberMainTabs).find((m) => m.toLowerCase() === pathTab?.toLowerCase()) || null;

  const isTabSelected = (tabName: MemberMainTabs) => {
    return selectedTab?.toLowerCase() === tabName.toLowerCase();
  };
  /** Change state and location (without navigating away), so links can take us here */
  const handleNavigateToTab = (tabName: MemberMainTabs, options?: { pushHistory: false }) => {
    if (isTabSelected(tabName)) {
      return;
    }
    // If we already selected a tab (3rd component of the path), replace it
    const userOrReviewId = match.params?.id;
    // pathFirstElement: /members or /patient
    const pathFirstElement = pathname.split('/').slice(0, 2).join('/');

    // Route for this page is /members/:id/:selectedTab or /patient/:id/:selectedTab
    const updatedPath = `${pathFirstElement}/${userOrReviewId}/${tabName.toLowerCase()}`;

    rpmEventTrackingServiceInstance.track({
      memberUuid: patient.uuid,
      measureName: `${RpmProviderEvent.ProviderViewMemberProfile}-${tabName.toLowerCase()}`,
    });

    if (options?.pushHistory === false) {
      history.replace(updatedPath);
    } else {
      history.push(updatedPath);
    }
  };

  useEffect(() => {
    if (selectedTab) {
      return;
    }
    if (
      isFromSendGridMissedAppointments(search) &&
      featuresState?.[EFeatures.ProviderShowCalendar]
    ) {
      handleNavigateToTab(MemberMainTabs.CALENDAR, { pushHistory: false });
    } else if (!reviewList) {
      handleNavigateToTab(MemberMainTabs.PROFILE, { pushHistory: false });
    } else if (CARD_REVIEW_LIST_NAMES.includes(reviewList)) {
      handleNavigateToTab(MemberMainTabs.CARDS, { pushHistory: false });
    } else if (PICTURES_REVIEW_LIST_NAMES.includes(reviewList)) {
      handleNavigateToTab(MemberMainTabs.PICTURES, { pushHistory: false });
    } else {
      handleNavigateToTab(MemberMainTabs.PROFILE, { pushHistory: false });
    }
  }, [selectedTab]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getPictureNotificationsSummary = async () => {
      let data;
      try {
        data = await PictureService.pictureNotificationsSummary(
          {
            memberId: patient.id,
          },
          source.token
        );
      } catch (err) {
        console.error(err);
      }
      if (data?.success === 'true') {
        setPicturesTabUnreviewed(data.foods.has_notifications);
      }
    };

    getPictureNotificationsSummary();
    return () => {
      source.cancel();
    };
  }, [featuresState, patient.id]);

  useEffect(() => {
    const resolveViewNotesStatus = async () => {
      const config = await AuthService.getConfig();
      if (config && config.provider.scribeConfig.viewNotes) {
        setDisplayNotes(true);
      }
    };
    resolveViewNotesStatus();
  }, []);

  const isShowMoreButtonVisible = featuresState?.[EFeatures.ProviderProfileTabsShowMore];
  const showAllTabs = !isShowMoreButtonVisible || displayMore;

  return (
    <>
      <Tabs>
        {/* TODO: Display tabs using array + map */}
        <Badge key={`badge-${MemberMainTabs.PROFILE}`} right={25} visible={profileTabUnreviewed}>
          <TabItem
            tabName={MemberMainTabs.PROFILE}
            isSelected={isTabSelected(MemberMainTabs.PROFILE)}
            onClick={() => handleNavigateToTab(MemberMainTabs.PROFILE)}
          />
        </Badge>

        <TabItem
          tabName={MemberMainTabs.ASSESSMENTS}
          isSelected={isTabSelected(MemberMainTabs.ASSESSMENTS)}
          isVisible={featuresState?.[EFeatures.ProviderShowPvq]}
          onClick={() => {
            handleNavigateToTab(MemberMainTabs.ASSESSMENTS);
          }}
        />
        <Badge key={`badge-${MemberMainTabs.PICTURES}`} right={25} visible={picturesTabUnreviewed}>
          <TabItem
            tabName={MemberMainTabs.PICTURES}
            isSelected={isTabSelected(MemberMainTabs.PICTURES)}
            onClick={() => handleNavigateToTab(MemberMainTabs.PICTURES)}
          />
        </Badge>
        <TabItem
          tabName={MemberMainTabs.CARDS}
          isSelected={isTabSelected(MemberMainTabs.CARDS)}
          onClick={() => handleNavigateToTab(MemberMainTabs.CARDS)}
        />
        <div
          data-tip={
            'Book medical, nutrition and exercise appointments, or single-session group classes. <br />' +
            'Select a day to view available time slots. Toggle between host and member time zones. <br />' +
            'Only available group classes will be shown. If multiple providers are listed per role, <br />' +
            'remove extras via Care Team assignment (accessible in the Profile tab).'
          }
          data-for='schedule-tab'>
          <ReactTooltip id='schedule-tab' place='top' type='dark' effect='float' multiline />
          <TabItem
            tabName={MemberMainTabs.SCHEDULE}
            isSelected={isTabSelected(MemberMainTabs.SCHEDULE)}
            isVisible
            onClick={() => {
              handleNavigateToTab(MemberMainTabs.SCHEDULE);
            }}
          />
        </div>
        {showAllTabs && (
          <>
            <TabItem
              tabName={MemberMainTabs.AGREEMENTS}
              isSelected={isTabSelected(MemberMainTabs.AGREEMENTS)}
              isVisible
              onClick={() => {
                handleNavigateToTab(MemberMainTabs.AGREEMENTS);
              }}
            />
            <TabItem
              tabName={MemberMainTabs.APPOINTMENTS}
              isSelected={isTabSelected(MemberMainTabs.APPOINTMENTS)}
              isVisible={featuresState?.[EFeatures.ProviderShowAppointments]}
              onClick={() => {
                handleNavigateToTab(MemberMainTabs.APPOINTMENTS);
              }}
            />
            <TabItem
              tabName={MemberMainTabs.CALENDAR}
              isSelected={isTabSelected(MemberMainTabs.CALENDAR)}
              isVisible={featuresState?.[EFeatures.ProviderShowCalendar]}
              onClick={() => {
                handleNavigateToTab(MemberMainTabs.CALENDAR);
              }}
            />
            <TabItem
              tabName={MemberMainTabs.RPM_EVENTS}
              isSelected={isTabSelected(MemberMainTabs.RPM_EVENTS)}
              isVisible={checkRole(user.roles, 'Billing')}
              onClick={() => {
                handleNavigateToTab(MemberMainTabs.RPM_EVENTS);
              }}
              mixpanelProps={{
                User: user.fullName,
                Role: user.role,
                Roles: user.roles.map((r) => r.first_name),
              }}
            />
            <TabItem
              tabName={MemberMainTabs.NOTES}
              isSelected={isTabSelected(MemberMainTabs.NOTES)}
              isVisible={displayNotes}
              onClick={() => {
                handleNavigateToTab(MemberMainTabs.NOTES);
              }}
            />
          </>
        )}
        {isShowMoreButtonVisible && (
          <TabItem
            tabName={displayMore ? '-' : '+'}
            isSelected={false}
            isVisible
            onClick={() => setDisplayMore(!displayMore)}
          />
        )}
      </Tabs>

      {isTabSelected(MemberMainTabs.PROFILE) && (
        <ProfileTabComponent
          patient={patient}
          key={`profile-${patient.id}`}
          setProfileTabUnreviewed={setProfileTabUnreviewed}
        />
      )}
      {isTabSelected(MemberMainTabs.ASSESSMENTS) && (
        <AssessmentTab patientId={patient.id} key={`assessment-${patient.id}`} />
      )}
      {isTabSelected(MemberMainTabs.PICTURES) && (
        <Pictures
          patientId={patient.id}
          key={`pictures-${patient.id}`}
          setPicturesTabUnreviewed={setPicturesTabUnreviewed}
        />
      )}
      {isTabSelected(MemberMainTabs.CARDS) && (
        <CardsPendingCommentsTab
          memberId={patient.id}
          reviewList={reviewList}
          key={`cards-${patient.id}`}
        />
      )}
      {isTabSelected(MemberMainTabs.CALENDAR) && (
        <CalendarTab patient={patient} key={`calendar-${patient.id}`} />
      )}
      {isTabSelected(MemberMainTabs.SCHEDULE) && (
        <ScheduleTab patient={patient} key={`schedule-${patient.id}`} />
      )}

      {isTabSelected(MemberMainTabs.RPM_EVENTS) && (
        <EventList memberUuid={patient.uuid} key={`rpm-event-${patient.id}`} />
      )}

      {isTabSelected(MemberMainTabs.APPOINTMENTS) && (
        <AppointmentListForTabs key={`appointment-tab-${patient.id}`} patientId={patient.id} />
      )}

      {isTabSelected(MemberMainTabs.AGREEMENTS) && (
        <Agreements key={`agreements-tab-${patient.id}`} memberId={patient.id} />
      )}

      {isTabSelected(MemberMainTabs.NOTES) && <Notes patient={patient} />}
    </>
  );
};

export default ProfileTabsBox;
